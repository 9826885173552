<template>
  <div>
    <div class="inline-row">
      <div class="row">
        <div 
          class="alert message-component col-sm-12 mt-sm-1 mt-md-0"
          :class="{ 'alert-secondary': !isEnabled, 'alert-primary': isEnabled}"
          role="alert"
        >
          {{ isEnabled ? "Currently in night mode" : "Currently in regular mode"}}
        </div>
      </div>
    </div>
    <ActionToolbar :secondary-enabled="false" :component-state="currentState" :error-message="formError"
      :primary-button-text="isEnabled ? 'Leave Night Mode' : 'Enter Night Mode'"
      :primary-style="isEnabled ? 'secondary' : 'primary'"
      :primary-button-icon="isEnabled ? ['fa', 'ban'] : ['fa', 'moon'] "
      @primary-click.prevent="enableOrDisableNightMode()"
    />
    <p class="small">
      Remember - in night mode there are no BTS dashboard reminder calls - you only get the regular UNet robocalls.
    </p>
  </div>
  
</template>
<script>
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import { useUserStore } from '@/src/stores/user';

export default {
  components: {
    ActionToolbar
  },
  props: {
    isEnabled: { required: true, type: Boolean }
  },
  mixins: [ savableStateMixin ],

  setup() {
    const userStore = useUserStore();
    return {
      userStore
    }
  },

  methods: {
    async enableOrDisableNightMode() {
      try {
        this.setSavableStateProcessing();
        if(this.isEnabled) {
          this.$emit('reload', await this.userStore.disableNightMode());
        }
        else {
          this.$emit('reload', await this.userStore.enableNightMode());
        }
        this.setSavableStateSuccess();
      } catch(error) {
        this.handleFormError(error);
      }
    },
  }
}
</script>