import beApiClient from "@/src/be-api-client";
import { APIRoute } from "~/src/endpoints";

// This mixin is responsible for common functionality used by analytics charts
export const chartMixin = {
  data() {
    return {
      chartLoading: true,
      chartError: '',
      chartResult: {},
      chartResults: [],
    };
  },
  methods: {
    getSingleChartResult(endpoint, params) {
      const _vm = this;
      const url = APIRoute(endpoint);
      _vm.startLoading();
      beApiClient.get(url, {
        headers: { 'Accept': 'application/json' },
        params: params,
      }).then((response) => {
        _vm.handleSingleResultResponse(response);
      }).catch((error) => {
        _vm.handleError(error);
      }).finally(() => {
        _vm.stopLoading();
      });
    },
    getChartResults(endpoint, params) {
      const _vm = this;
      const url = APIRoute(endpoint);
      _vm.startLoading();
      beApiClient.get(url, {
        headers: { 'Accept': 'application/json' },
        params: params,
      }).then((response) => {
        _vm.handleResultsResponse(response);
      }).catch((error) => {
        _vm.handleError(error);
      }).finally(() => {
        _vm.stopLoading();
      });
    },
    startLoading() {
      this.chartLoading = true;
      this.chartResult = {};
      this.chartResults = [];
      this.chartError = '';
    },
    stopLoading() {
      this.chartLoading = false;
    },
    handleSingleResultResponse(response) {
      const analyticsResult = response.data?.analytics_result;
      if (analyticsResult == null) this.handleError({ message: 'Missing analytics result' });

      this.chartResult = analyticsResult;
    },
    handleResultsResponse(response) {
      const analyticsResults = response.data?.analytics_results;
      if (analyticsResults == null) this.handleError({ message: 'Missing analytics results' });

      this.chartResults = analyticsResults;
    },
    handleError(error) {
      console.warn(error);
      const errorMessage = error.response?.data?.errors || error.message;
      this.chartError = errorMessage;
    },


    getSelectedOrganGroups(organ_ids, organ_groups, value) {
      let options = []
      if (value && value.label) {
        options.push((organ_groups || []).find(obj => obj.name == value.label));
      }
      else {
        options = (organ_groups || []).filter((option) => { return organ_ids.includes(option.id); });
      }

      return options
    },

    deriveFirstSelectedTransplantCenter(chart_results, transplant_centers, filters, value) {
      let firstSelectedTransplantCenterOption = null
      if (value && value.label) {
        const organResult = chart_results.find((result) => result.organ_name == value.label);
        firstSelectedTransplantCenterOption = organResult.transplant_center_codes[0] || null;
      }
      else {
        const selectedTransplantCenterIds = filters.transplant_centers || [];
        const selectedTransplantCenterOptions = (transplant_centers || []).filter((option) => { return selectedTransplantCenterIds.includes(option.id); });
        const selectedTransplantCenterCodes = selectedTransplantCenterOptions.map((option) => { return option.code; });

        firstSelectedTransplantCenterOption = selectedTransplantCenterCodes[0] || null
      }

      return firstSelectedTransplantCenterOption
    },

    //Generic Preview reports. There are a couple more specialized versions of this method for specific charts
    previewReport(value) {
      const routeData = this.$router.resolve({ name: 'external-dash-reports', query: this.previewReportsQuery(value) });

      window.open(routeData.href, '_blank');
    },

    previewReportsQuery(value, non_recoveries = false, final_disposition_override = null) {
      const filters = this.filters || {};

      // When we have non recoveries we want to send null instead of a value as they both use the same data section on the chart.
      const firstSelectedTransplantCenterOption = this.deriveFirstSelectedTransplantCenter(this.chartResults, this.transplant_centers, filters, non_recoveries ? null : value)

      const selectedOrganGroupIds = filters.organ_groups.split(',') || [];
      const selectedOrganGroupOptions = this.getSelectedOrganGroups(selectedOrganGroupIds, this.organ_groups, non_recoveries ? null : value);
      const selectedOrganGroupCodes = selectedOrganGroupOptions.map((option) => { return option.organ_code; });

      const startDate = new Date(filters.year, filters.month-1, 1); 
      const endDate = new Date(filters.year, filters.month, 0); 
    
      const startDateString = startDate.toISOString().split('T')[0];
      const endDateString = endDate.toISOString().split('T')[0];
    
      const offer_date = [startDateString, endDateString];
    
      let finalDispositionFilter = []
      if (value && value.section) {
        finalDispositionFilter.push(value.section)
      }

      // Override final disposition. Used in cases where we pass in one static code such as Percent Accepted after decline.
      if(final_disposition_override) {
        finalDispositionFilter = []
        finalDispositionFilter.push(final_disposition_override)
      }


      return {
        'report_slug': 'monthly-organ-report',
        'offer_date': JSON.stringify(offer_date),
        'organ_groups': JSON.stringify(selectedOrganGroupCodes),
        'transplant_centers': JSON.stringify(firstSelectedTransplantCenterOption),
        'final_dispositions': JSON.stringify(finalDispositionFilter),
        'auto_rule_out': JSON.stringify(this.aro),
        'transplanted': JSON.stringify(this.transplanted)
      };
    }
  },
}
