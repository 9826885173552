<template>
  <div class="content-wrap" id="offerDetails" v-if="offerDetails">
    <form @submit.prevent="saveOfferDetails" v-if="!loading">
      <fieldset>
        <div class="row">
          <div class="standard-form-group">
            <label for="donorId">Donor Id</label>
            <input id="donorId" type="text" class="form-control" v-model="offerDetails.donor_code" disabled>
          </div>
          <div class="standard-form-group">
            <label for="match">
              <span>Match</span>
            </label>
            <input id="match" type="text" class="form-control" v-model="offerDetails.match_code" disabled>
          </div>
          <multi-select-input
            :disabled="true"
            name="transplant_center"
            label="TxC"
            :options="transplant_centers"
            labelKey="code"
            valueKey="code"
            colStyle="standard-form-group"
            v-model="offerDetails.tc">
          </multi-select-input>
        </div>
      </fieldset>
      <div class="sub-divider"></div>
      <fieldset>
        <div class="row">
          <multi-select-input
            name="organ"
            label="Organ"
            :options="organGroups"
            labelKey="name"
            valueKey="id"
            colStyle="standard-form-group"
            rules="required"
            v-model="offerDetails.organGroup">
          </multi-select-input>

          <div class="standard-form-group">
            <label for="offerDate">
              <span>Offer Date</span>
            </label>
            <input type="datetime-local"
                   class="form-control"
                   :max="maxDate"
                   v-model="offerDetails.offerDate">
          </div>
          <div class="standard-form-group">
            <label for="sequence" class="required">
              <span>Sequence (first)</span>
            </label>
            <input id="sequence" type="number" min="1" class="form-control" v-model="offerDetails.sequence"
                   required>
          </div>
          <div class="standard-form-group">
            <p class="p-label" :class="`${isAutoRuleOutRequired ? 'required' : ''}`">Auto rule out</p>
            <div class="form-check form-check-inline">
              <input id="autoRuleOut"
                aria-labelledby="autoRuleOut"
                v-model="offerDetails.autoRuleOut"
                :required="isAutoRuleOutRequired"
                @change="setPhysician()"
                :disabled="disableautoRuleOut"
                type="checkbox" class="form-check-input">
              <label for="autoRuleOut" class="form-check-label">
                <span>Yes</span>
              </label>
            </div>
          </div>
          <div class="standard-form-group">
            <p class="p-label" :class="`${isPhysicianConsultedRequired ? 'required' : ''}`">Physician Consulted</p>
            <div class="form-check form-check-inline">
              <input id="physicianConsulted"
                     aria-labelledby="physicianConsulted"
                     v-model="offerDetails.physicianConsulted"
                     :disabled="disablePhysicianConsulted"
                     :required="isPhysicianConsultedRequired"
                     type="checkbox" class="form-check-input">
              <label for="physicianConsulted" class="form-check-label">
                <span>Yes</span>
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group-standard-column">
            <label for="declinePhysician" :class="`${isPhysicianRequired ? 'required' : ''}`">
              <span>Physician Name</span>
            </label>
            <input id="declinePhysician" type="text" class="form-control" v-model="offerDetails.declinePhysician"
                   :required="isPhysicianRequired">
          </div>
          <multi-select-input
            name="outcome"
            label="Outcome"
            :options="outcomes"
            labelKey="name"
            valueKey="id"
            colStyle="form-group-standard-column"
            rules="required"
            placeholder="Select..."
            v-model="offerDetails.outcome">
          </multi-select-input>
        </div>
        <div class="row">
          <multi-select-input
            name="declineOrBypassCode1"
            label="Primary Decline / Bypass code"
            :options="getBypassAndRefusalCodes"
            labelKey="label"
            valueKey="code"
            colStyle="form-group-8-column-large"
            :rules="`${isPrimaryDeclineOrBypassCodeRequired ? 'required' : ''}`"
            placeholder="Select..."
            v-model="offerDetails.declineOrBypassCode1">
          </multi-select-input>

          <multi-select-input
            name="declineOrBypassCode2"
            label="Secondary Decline / Bypass codes"
            :options="getBypassAndRefusalCodes"
            labelKey="label"
            valueKey="code"
            colStyle="form-group-8-column-large"
            :rules="`${isPrimaryDeclineOrBypassCodeRequired ? 'required' : ''}`"
            placeholder="Select..."
            v-model="offerDetails.declineOrBypassCode2">
          </multi-select-input>

        </div>
        <div class="row">
          <div class="form-group-8-column-large">
            <label for="declineNote" :class="`${isDeclineNotesRequired ? 'required' : ''}`">Decline Note</label>
            <textarea id="declineNote" rows="10" class="form-control" v-model="offerDetails.declineNote"
                      :required="isDeclineNotesRequired"/>
          </div>
          <div class="form-group-8-column-large">
            <label for="coordinatorNotes">Coordinator Notes</label>
            <textarea id="coordinatorNotes" rows="10" class="form-control" v-model="offerDetails.coordinatorNotes"/>
          </div>  
        </div>
        <save-toolbar @cancel="cancel"/>
      </fieldset>
    </form>
  </div>
</template>

<script lang="js">
import beApiClient from "@/src/be-api-client";
import {APIRoute, EP} from "~/src/endpoints";
import {format, toZonedTime} from "date-fns-tz";
import SaveToolbar from "@/src/components/shared/SaveToolbar.vue";
import MultiSelectInput from "@/src/components/shared/FormComponents/MultiSelectInput.vue";

export default {
  components: { 
    MultiSelectInput, 
    SaveToolbar 
  },
  props: ['offerInfo'],
  data() {
    return {
      saving: false,
      success: -1,
      loading: false,
      errorMessage: '',
      organGroups: [],
      outcomes: [],
      offerDetails: {},
      bypass_and_refusal_codes: [],
      transplant_centers: [],
      organs: [],
    }
  },
  async mounted() {
    await this.getBaseAccessData();
    this.setupOffer(this.offerInfo);
  },
  watch: {
    offerInfo(newVal) {
      this.setupOffer(newVal);
    }
  },
  computed: {
    getBypassAndRefusalCodes() {
      const filtered = this.bypass_and_refusal_codes.map((item) => {
        return { code: item.code, label: `${item.code} - ${item.description}`};
      })
      return filtered;
    },
    maxDate: function () {
      // do not allow future dates more than 24 hours ahead of current time
      const today = new Date();
      today.setDate(today.getDate() + 1);
      return format(today, 'yyyy-MM-dd HH:mm');
    },
    getOutcomeDeclined: function () {
      if (!this.outcomes && this.outcomes.length == 0) return false;

      const outcome = this.outcomes.find(outcome => {
        return outcome.identifier == 'declined';
      });
      return outcome;
    },
    getOutcomeMadeCase: function () {
      if (!this.outcomes && this.outcomes.length == 0) return false;

      const outcome = this.outcomes.find(outcome => {
        return outcome.identifier == 'made_case';
      });
      return outcome;
    },
    isPrimaryDeclineOrBypassCodeRequired: function () {

      if (this.offerDetails.declineNote || this.offerDetails.autoRuleOut || this.offerDetails.outcome == this.getOutcomeDeclined?.id) return true;
      return false;
    },
    isDeclineNotesRequired: function () {
      if (this.offerDetails.autoRuleOut || this.offerDetails.physicianConsulted || this.offerDetails.declineOrBypassCode1 || this.offerDetails.declinePhysician || this.offerDetails.outcome == this.getOutcomeMadeCase?.id) {
        return true;
      }
      return false;
    },
    isPhysicianRequired: function () {
      if (this.offerDetails.physicianConsulted || this.offerDetails.declineOrBypassCode1 && !this.offerDetails.autoRuleOut || (this.offerDetails.outcome == this.getOutcomeDeclined?.id && !this.offerDetails.autoRuleOut) || this.offerDetails.outcome == this.getOutcomeMadeCase?.id || this.offerDetails.autoRuleOut) {
        return true;
      }
      return false;
    },
    isPhysicianConsultedRequired: function () {
      if (this.offerDetails.declineOrBypassCode1 && !this.offerDetails.autoRuleOut || (this.offerDetails.outcome == this.getOutcomeDeclined?.id && !this.offerDetails.autoRuleOut) || this.offerDetails.outcome == this.getOutcomeMadeCase?.id) {
        return true;
      }
      return false;
    },
    isAutoRuleOutRequired: function () {
      if (this.offerDetails.declineOrBypassCode1 && !this.offerDetails.physicianConsulted || (this.offerDetails.outcome == this.getOutcomeDeclined?.id && !this.offerDetails.physicianConsulted)) {
        return true;
      }
      return false;
    },
    disablePhysicianConsulted: function () {
      if (this.offerDetails.autoRuleOut) {
        this.offerDetails.physicianConsulted = false;
        return true;
      }
      return false;
    },
    disableautoRuleOut: function () {
      if (this.offerDetails.physicianConsulted || this.offerDetails.outcome == this.getOutcomeMadeCase?.id) {
        this.offerDetails.autoRuleOut = false;
        return true;
      }
      return false;
    }
  },
  methods: {
    /**
     * Parse important data about the offer from an object and load it up
     * @param {Object} payload
     * @param {String} payload.donor_code
     * @param {String} payload.match_code
     * @param {String} payload.tc
     */
    setupOffer(payload) {
      if(!payload || !payload.donor_code || !payload.match_code || !payload.tc) return;
      this.offerDetails.match_code = payload.match_code;
      this.offerDetails.donor_code = payload.donor_code;
      this.offerDetails.tc = payload.tc;
      this.getOfferDetails();
    },
    // Set Physician value to 'Auto rule out' on auto rule out flag checked and no physician is specified
    // On uncheck, if physician value is 'Auto rule out', set physician to empty
    setPhysician() {
      if (this.offerDetails.autoRuleOut && !this.offerDetails.declinePhysician) {
        this.offerDetails.declinePhysician = 'Auto rule out';
      } else {
        if (this.offerDetails.declinePhysician == 'Auto rule out') this.offerDetails.declinePhysician = '';

      }
    },
    getOfferDetails() {
      this.loading = true;
      const payload = {
        donor_code: this.offerDetails.donor_code,
        match_code: this.offerDetails.match_code,
        tc: this.offerDetails.tc
      }
      beApiClient.get(APIRoute(EP.matches.show), { params: payload }).then((response) => {
        this.parseOfferDetails(response.data);
      }).catch(error => {
        // If it's a 400 or 404 we don't care
        if(error.response.status === 404 || error.response.status === 400) {
          return;
        }
        alert('Unable to get offer details');
        console.log(error);
      }).finally(() => {
        this.loading = false;
      });

    },
    parseOfferDetails(details) {
      const currentDate = format(new Date(), 'yyyy-MM-dd HH:mm')
      this.offerDetails = {
        matchId: details.id,
        donor_id: details.donor.id,
        donor_code: details.donor.code,
        match_code: details.code,
        tc: details.transplant_center.code,
        organGroup: details?.organ?.organ_group.id,
        organ: details?.organ?.id,
        sequence: details?.offers_ordered_by_seq[0]?.seq || 'N/A',
        autoRuleOut: details.auto_rule_out,
        physicianConsulted: details.physician_consulted,
        declinePhysician: details.physician,
        declineOrBypassCode1: details?.offers_ordered_by_seq[0]?.decline_code_1,
        declineOrBypassCode2: details?.offers_ordered_by_seq[0]?.decline_code_2,
        declineNote: details.decline_note,
        coordinatorNotes: details?.coordinator_note?.note,
        outcome: details.outcome_id,
        offerDate: details?.offer_date ? this.formatDateTime(details.offer_date) : currentDate
      }
    },
    formatDateTime(datetime) {
      const dt = new Date(datetime);
      return format(toZonedTime(dt, "UTC"), 'yyyy-MM-dd HH:mm', {timeZone: "UTC"});
    },
    close() {
      this.errorMessage = '';
      this.success = -1;
    },
    async getBaseAccessData() {
      this.loading = true;
      try {
        const organGroups = await beApiClient.get(APIRoute(EP.admin.organ_groups.index));
        const outcomes = await beApiClient.get(APIRoute(EP.outcomes.index));
        const bypass_and_refusals = await beApiClient.get(APIRoute(EP.bypass_and_refusals.index));
        const transplant_centers = await beApiClient.get(APIRoute(EP.transplant_centers.index));
        const organs = await beApiClient.get(APIRoute(EP.organs.index));

        this.organGroups = organGroups.data || [];
        this.outcomes = outcomes.data || [];
        this.bypass_and_refusal_codes = bypass_and_refusals.data || [];
        this.transplant_centers = transplant_centers.data || [];
        this.organs = organs.data || [];
      } catch(error) {
        this.errorMessage = error;
      }
      this.loading = false
    },
    saveOfferDetails() {
      this.saving = true;
      const declinePhysician = this.offerDetails.autoRuleOut && !this.offerDetails.declinePhysician ? 'Auto rule out' : this.offerDetails.declinePhysician;
      const selectedOrganGroup = this.organGroups.find(organ_group => {
        return organ_group.id == this.offerDetails.organGroup;
      });

      // Match allows only organs, so we map organ group to the organ 
      const selectedOrgan = this.organs.find(organ => {
        return selectedOrganGroup ? organ.name == selectedOrganGroup.name : null;
      });

      const updatedMatch = {
        organ_id: selectedOrgan?.id,
        auto_rule_out: this.offerDetails.autoRuleOut,
        physician_consulted: this.offerDetails.physicianConsulted,
        physician: declinePhysician,
        decline_code_1: this.offerDetails.declineOrBypassCode1,
        decline_code_2: this.offerDetails.declineOrBypassCode2,
        decline_note: this.offerDetails.declineNote,
        coordinator_note: this.offerDetails.coordinatorNotes,
        outcome_id: this.offerDetails.outcome,
        donor: this.offerDetails.donor_code,
        match: this.offerDetails.match_code,
        tc: this.offerDetails.tc,
        seq: this.offerDetails.sequence,
        offer_date: this.offerDetails.offerDate
      }
      const method = beApiClient.post;
      const route = this.offerDetails.matchId ? APIRoute(EP.matches.update, {match_id: this.offerDetails.matchId}, {
        params: {
          tc: this.offerDetails.tc,
          donor: this.offerDetails.donor
        }
      }) : APIRoute(EP.donors.create);
      method(route, {match: updatedMatch}).then(response => {
        this.success = 1;
        window.setTimeout(() => {
          this.cancel();
        }, 1500);
      }).catch(e => {
        this.errorMessage = e;
        this.success = 0;
      }).finally(() => {
        this.saving = false;
      });
    },
    cancel() {
      this.success = 0;
      this.$emit('close');
    }
  }
}
</script>
<style>
.required:after {
  content: " *";
  color: red;
}
</style>
