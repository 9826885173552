export const EP = {
  application: {
    settings: '/application_settings'
  },
  current_user: {
    show: '/current_user',
    update_preferences: '/current_user/ui_preference',
    update_profile: '/current_user/update_profile',
    on_call: '/current_user/on_calls/put_on_call',
    off_call: '/current_user/on_calls/take_off_call',
    request_voip_number: '/current_user/request_voip_number',
    test_phone_number: '/current_user/test_phone_number',
    enable_night_mode: '/current_user/enable_night_mode',
    disable_night_mode: '/current_user/disable_night_mode',
    team_groups: {
      index: '/current_user/team_groups',
     
    },
    team_group_availabilities: {
      index: '/current_user/team_group_availabilities',
      create: '/current_user/team_group_availabilities',
      update: '/current_user/team_group_availabilities'
    }
  },
  reports: {
    qa_report: {
      upload: '/report/qa_report'
    },
  },
  external_clinical: {
    matches: {
      recipients: {
        index: 'external/matches/:match_id/recipients'
      },
      show: 'external/matches/:id',
    },
    donors: {
      show: 'external/donors/:id',
    },
    dashboard: {
      index: '/external_clinical_dashboard'
    }
  },
  external_analytics: {
    total_offers: '/external/analytics/total_offers',
    total_transplanted_organs: '/external/analytics/total_transplanted_organs',
    percent_accepted_after_decline: '/external/analytics/percent_accepted_after_decline',
    percent_auto_rule_outs_for_fully_declined_offers: '/external/analytics/percent_auto_rule_outs_for_fully_declined_offers',
    chart_organ_offers_received: '/external/analytics/chart_organ_offers_received',
    chart_transplanted_organs: '/external/analytics/chart_transplanted_organs',
    chart_non_recovery: '/external/analytics/chart_non_recovery',
    chart_final_dispositions: '/external/analytics/chart_final_dispositions',
  },
  coordinator_dashboard: {
    index: '/coordinator_dashboard',
    plugin: '/coordinator_dashboard/plugin',
    manual: '/coordinator_dashboard/manual'
  },
  unfinished_business_dashboard: {
    index: '/unfinished_business_dashboard'
  },
  offers: {
    update: '/offers/:id'
  },
  matches: {
    alternative_notifications: '/matches/:match_id/alternative_notifications',
    show: '/matches/:id',
    validate_match: '/matches/validate_match',
    decline: '/matches/:id',
    update: '/matches/:match_id/update_match',
    make_case: '/matches/:match_id/make_case',
    unmake_case: '/matches/:match_id/unmake_case',
    activate: '/matches/:match_id/activate',
    dismiss: '/matches/:match_id/dismiss',
    recipients: {
      index: 'matches/:match_id/recipients'
    },
    aro_rules: {
      index: '/matches/:match_id/aro_rules',
    },
    procedure_notes: {
      show: '/matches/:match_id/procedure_notes'
    },
    applicable_aro_rules: {
      index: '/matches/:match_id/applicable_aro_rules',
      create: '/matches/:match_id/applicable_aro_rules',
      update: '/matches/:match_id/applicable_aro_rules',
      reset: '/matches/:match_id/applicable_aro_rules/reset'
    }
  },
  coordinator_notes: {
    index: '/matches/:match_id/coordinator_notes',
    update: '/matches/:match_id/coordinator_notes'
  },
  alternative_notifications: {
    dismiss: '/alternative_notifications/:id/dismiss'
  },
  donors: {
    show: '/donors/:id',
    create: '/donors'
  },
  recipients: {
    show: '/recipients/:id'
  },
  non_recoveries: {
    index: '/non_recoveries'
  },
  outcomes: {
    index: '/outcomes'
  },
  organs: {
    index: '/organs'
  },
  bypass_and_refusals: {
    index: '/bypass_and_refusals',
    decline_codes: '/bypass_and_refusals/decline_codes'
  },
  transplant_centers: {
    index: '/transplant_centers',
  },
  admin: {
    templates: {
      index: '/admin/communication_templates',
      create: '/admin/communication_templates',
      show: '/admin/communication_templates/:id',
      update: '/admin/communication_templates/:id',
      verify: '/admin/communication_templates/get_template',
      validate_match: '/matches/validate_match',
      preview: '/admin/communication_templates/:template_id/preview'
    },
    templates_categories: {
      index_all: '/admin/communication_template_categories',
      index: '/admin/communication_template_categories',
      create: '/admin/communication_template_categories',
      show: '/admin/communication_template_categories/:id',
      update: '/admin/communication_template_categories/:id',
      metadata_fields: '/admin/communication_template_categories_metadata_fields/category_fields',
      category_types: '/admin/communication_template_categories/category_types',
      category_types_by_organGroups_and_txc: '/communication_template_categories'
    },
    template_generation: {
      metadata_fields: '/communication_template_categories/:communication_template_category_id/category_fields',
      // get latest instance (they are versioned, this will return the latest one)
      latestInstance: '/matches/category_instances',
      // For the selected template and match, it give the computed template body (variables will be replaced with values based on the match)
      generate_instance_body: '/communication_templates/:communication_template_id',
      // Creates the communication template instance for the match
      generate_template_instance_from_match: '/matches/:match_id/communication_template_instances',
      // show and update the communication template instance for the match
      template_instance: '/matches/:match_id/communication_template_instances/:communication_template_instances_id',
      // Resets the communication template instance for the match with recalculated template_body from the original communication template
      reset_template_instance: '/matches/:match_id/communication_template_instances/:communication_template_instances_id/reset',
    },
    converter_variables: {
      index: '/admin/converter_variables'
    },
    donors: {
      index: '/admin/donors',
      reprocess: '/admin/donors/:id/reprocess'
    },
    teams: {
      index: '/admin/teams',
      create: '/admin/teams',
      show: '/admin/teams/:id',
      update: '/admin/teams/:id',
      delete: '/admin/teams/:id',
      users_on_team: '/admin/teams/:team_id/user_memberships',
      users_missing_from_team: '/admin/teams/:team_id/user_memberships/users_with_no_access',
      add_users_to_team: '/admin/teams/:team_id/user_memberships',
      delete_membership: 'admin/teams/:team_id/user_memberships/delete_membership',
      groups: {
        index: '/admin/teams/:team_id/team_groups',
        show: '/admin/teams/:team_id/team_groups/:id',
        create: '/admin/teams/:team_id/team_groups',
        update: '/admin/teams/:team_id/team_groups/:id',
        disable: '/admin/teams/:team_id/team_groups/:team_group_id/disable',
        enable: '/admin/teams/:team_id/team_groups/:team_group_id/enable'
      }
    },
    membership_categories: {
      index: '/admin/membership_categories',
      create: '/admin/membership_categories',
      filter_categories:'/admin/membership_categories',
      show: '/admin/membership_categories/:id',
      update: '/admin/membership_categories/:id',
    },
    organ_groups: {
      index: '/admin/organ_groups',
      show: '/admin/organ_groups/:id',
      create: '/admin/organ_groups',
      update: '/admin/organ_groups/:id',
    },
    organs: {
      index: '/admin/organs',
      update: '/admin/organs/:id'
    },
    users: {
      index: '/admin/users',
      show: '/admin/users/:id',
      create: '/admin/users',
      update: '/admin/users/:id',
      disable: '/admin/users/:user_id/disable',
      enable: '/admin/users/:user_id/enable',
      invite: '/admin/users/:user_id/invite',
      mass_import: '/admin/users/mass_import',
      delete_membership: 'admin/users/:user_id/team_memberships/delete_membership',
      teams_on_users: '/admin/users/:user_id/team_memberships',
      teams_missing_from_users: '/admin/users/:user_id/team_memberships/teams_with_no_access',
      add_teams_to_user: '/admin/users/:user_id/team_memberships',
      update_membership: '/admin/users/:user_id/team_memberships/:id',
      permitted_login_mechanisms: '/admin/users/permitted_login_mechanisms',
      request_number: '/admin/users/:user_id/request_number',
      release_number: '/admin/users/:user_id/release_number',
      test_phone_number: '/admin/users/:user_id/test_phone_number'
    },
    transplant_centers: {
      index: '/admin/transplant_centers',
    },
    roles: {
      index: '/admin/roles',
      show: '/admin/roles/:id',
      update: '/admin/roles/:id',
      create: '/admin/roles'
    },
    aro_rule_categories: {
      index: '/admin/aro_rule_categories',
    },
    aro_rules: {
      index: '/admin/aro_rules',
      update: '/admin/aro_rules/:id',
      create: '/admin/aro_rules',
      show: '/admin/aro_rules/:id',
      disable: '/admin/aro_rules/:aro_rule_id/disable',
      enable: '/admin/aro_rules/:aro_rule_id/enable',
    },
    procedure_notes: {
      index: '/admin/procedure_notes',
      update: '/admin/procedure_notes/:id',
      create: '/admin/procedure_notes',
      show: '/admin/procedure_notes/:id',
      validate: 'admin/procedure_notes/validate'
    },
    application_configurations: {
      robocall_reduction: {
        show: '/admin/application_configurations/robocall_reduction',
        enable: '/admin/application_configurations/robocall_reduction/enable',
        disable: '/admin/application_configurations/robocall_reduction/disable',
        update: '/admin/application_configurations/robocall_reduction',
      }
    },
    permissions: {
      index: '/admin/permissions'
    },
    integrations: {
      omnilife: {
        workflow_mappings: {
          index: '/admin/integrations/omnilife/workflow_mappings'
        },
        user_mappings: {
          index: '/admin/integrations/omnilife/user_mappings'
        }
      }
    },
    troubleshoot: {
      matches: {
        search: '/admin/troubleshoot_matches/search'
      }
    }
  }
}

/**
 *
 * @param {String} endpoint
 * @param {Object} [replace] (optional) Object
 * @returns String
 */
 export function APIBaseRoute(endpoint, replace = null) {
  let ep = endpoint;
  if (replace) {
    replace.forEach((r) => {
      ep = ep.replace(r[0], r[1]);
    });
  }
  return ep;
}


/**
 *
 * @param {String} endpoint
 * @param {Object} [replace] (optional) Object
 * @returns String
 */
export function APIRoute(endpoint, replace = null) {
  let ep = endpoint;
  if (replace) {
    Object.keys(replace).forEach(k => {
      const toReplace = `:${k}`;
      ep = ep.replace(toReplace, replace[k]);
    });
  }
  return ep;
}

/**
 * converts a hash into a valid uri params syntax
 *
 * i.e. {a: 1, b: 2} => a=1&b=2
 *
 * @param params object to convert
 * @returns {string} string with the uri converted params
 */
export function urlParams(params) {
  return Object.keys(params).map((k) => {
    if (params[k] !== null && Array.isArray(params[k])) {
      const ids = params[k].join(',');
      return params && params[k] ? k+'='+ids : null;
    } else if (params[k] !== null && params[k].startsWith('{') && params[k].endsWith('}')) {
      return params[k].slice(1,-1).replace(/\[/g, k +'[');
    } else {
      return params && params[k] ? k+'='+params[k] : null;
    }
  }).filter((p) => { return p && p.length >= 0; }).join('&');
}
