<template>
  <CardSection :loading="isSavableStateLoading" :error="majorError">
    <template #header>
      {{ createNew ? 'New' : 'Edit' }} User {{ selectedUser ? `- ${selectedUser.full_name}` : '' }}
    </template>
    <template #body>
      <SubSection :sub-section-heading="createNew ? '' : 'Profile'" :sub-section-nav="subSectionNav">              
        <template #body>
          <template v-if="!isSavableStateLoading && selectedUser">
            <UserDisabled :user="selectedUser" />

            <VeeForm @submit="onSubmit">
              <div class="row">
                <TextInput v-model="selectedUser.full_name"
                  name="full_name"
                  label="User Name"
                  rules="required"
                  col-style="form-group-5-column-large" />
              </div>
              <div class="row">
                <TextInput v-model="selectedUser.email"
                  name="email"
                  label="Email"
                  rules="required|email"
                  col-style="form-group-5-column-large" />
              </div>
              <div class="row">
                <TextInput v-model="selectedUser.phone_number" 
                  v-mask="'###-###-####'"
                  name="phone_number"
                  label="Cell number"
                  placeholder="123-456-7890"
                  col-style="form-group-5-column-large" @change="setDirty()" />
              </div>
              <div class="row">
                <TextInput v-model="selectedUser.night_mode_status" 
                  name="night_mode_status"
                  label=" Robocall Reduction - Night Mode Status"
                  disabled
                  col-style="form-group-5-column-large" />
              </div>
              <div class="row">
                <MultiSelectInput v-model="selectedUser.membership_categories"
                  name="membership_categories"
                  label="Membership Categories"
                  :options="membershipCategories"
                  label-key="name"
                  value-key="id"
                  option-disable-key="disable"
                  rules="required"
                  :multiple="true"
                  :show-toggle-all="false"
                  col-style="form-group-5-column-large" />
              </div>
              <div class="row">
                <MultiSelectInput v-model="selectedUser.permitted_login_mechanism"
                  name="permitted_login_mechanism"
                  label="User login mechanism"
                  :options="userStore.permittedLoginMechanisms"
                  label-key="name"
                  value-key="id"
                  option-disable-key="disable"
                  rules="required"
                  col-style="form-group-5-column-large" />
              </div>
              <div v-if="selectedUser.team_memberships.length > 0" class="row mb-2">
                <div class="col-12 text-secondary">
                  Membership Categories in which the user already has team membership appear as <em>locked</em> and cannot be removed.<br>
                  Please first remove those teams from the user and then proceed to remove those Membership Categories.
                </div>
              </div>
              <ActionToolbar :component-state="currentState" 
                :error-message="formError" 
                :success-message="successMessage" 
                :primary-button-text="createNew ? 'Create User' : 'Save User'"
                :secondary-button-text="createNew ? 'Cancel' : 'Close'"
                tertiary-button-text="Test Message"
                :tertiary-enabled="true"
                :tertiary-disabled="!isPhoneTestButtonEnabled"
                @secondary-click="cancel"
                @tertiary-click="testPhoneNumber" />
            </VeeForm>
          </template>
        </template>
      </SubSection>
    </template>
  </CardSection>
</template>
<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import SubSection from "@/src/components/shared/SubSection.vue";
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import MultiSelectInput from "@/src/components/shared/FormComponents/MultiSelectInput.vue";
import TextInput from "@/src/components/shared/FormComponents/TextInput.vue";
import '@/src/vee-validate.js';
import { Form as VeeForm } from 'vee-validate';
import { useUserStore } from '@/src/stores/user';
import { pageMixin } from "@/src/mixins/pageMixin";
import UserDisabled from "@/src/components/admin-area/users/_userDisabled.vue";
import {userSubSectionNavMixin} from "@/src/components/admin-area/users/userSubSectionNavMixin";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";


export default {
  components: {
    CardSection,
    SubSection,
    MultiSelectInput,
    TextInput,
    VeeForm,
    UserDisabled,
    ActionToolbar
  },
  mixins: [ pageMixin, savableStateMixin , userSubSectionNavMixin ],
  setup() {
    const userStore = useUserStore();
    return { userStore }
  },
  data() {
    return {
      successMessage: null,
      selectedUser: null,
      isSaved: false,
      dirty: false
    }
  },
  computed: {
    membershipCategories() {
      return this.userStore.membershipCategories.map((item) => {
        // Find all teams with same membership category
        const membership_exists = this.selectedUser.team_memberships.filter((membership) => {
          return membership.team.membership_category_id == item.id
        });
        return {
          id: item.id,
          name: item.name,
          disable:  membership_exists.length > 0
        }
      })
    },
    createNew() {
      return this.$route.params.id ? false : true;
    },
    isPhoneTestButtonEnabled() {
      // Test button is enabled only if the value is saved.
      return this.isSaved && this.selectedUser.phone_number !== null && !this.dirty;
    }
  },
  async mounted() {
    try {
      this.setSavableStateLoading();
      if(!this.createNew) {
        const response = await this.userStore.loadUser(this.$route.params.id);
        this.parseUserResponse(response);
      }
      else{
        this.selectedUser =  {
          fullName: null,
          email: null,
          phone_number: null,
          membershipCategories: null,
          team_memberships: []
         }
        }
      await this.userStore.loadMembershipCategories();
      await this.userStore.loadPermittedLoginMechanisms();  
      
      // if we have came from the create user page, show the banner
      const history = this.$router.options.history.state;
      this.setSavableStateIdle();
      if (!history.replaced && history.back == '/ui/admin/users/new') {
        // We need set time out to wait for the action toolbar to render first
        setTimeout(() => {
          this.successMessage = 'Successfully created User!'
          this.setSavableStateSuccess();
        }, 100);
      }
    } catch(error) {
      this.handleMajorError(error);
    }
  },
  methods: {
    setDirty() {
      this.dirty = true;
    },
    parseUserResponse(response) {
      this.isSaved = response.phone_number ? true : false;
      this.selectedUser = {
        full_name: response.full_name,
        email: response.email,
        phone_number: response.phone_number ? response.phone_number.substring(2) : null,
        deactivated_at: response.deactivated_at,
        membership_categories: response.membership_categories.map((item) => {
          return item.id;
        }),
        team_memberships: response.team_memberships,
        permitted_login_mechanism: response.permitted_login_mechanism,
        night_mode_status: response.night_mode_status ? 'On' : 'Off'
      }
    },
    async onSubmit() {
      let response;
      try {
        this.setSavableStateProcessing();
        let formattedPhoneNumber = null;
        if (this.selectedUser.phone_number && !this.selectedUser.phone_number.startsWith('+1')) {
          formattedPhoneNumber = '+1' + this.selectedUser.phone_number;
        } else {
          formattedPhoneNumber = this.selectedUser.phone_number;
        }
        const payload = {
          full_name: this.selectedUser.full_name,
          email: this.selectedUser.email,
          phone_number: formattedPhoneNumber ? formattedPhoneNumber.replace(/-/g, '') : null,
          membership_categories: this.selectedUser.membership_categories,
          permitted_login_mechanism: this.selectedUser.permitted_login_mechanism,
        }
        if (this.createNew) {
          response = await this.userStore.createUser(payload);
          this.successMessage = 'Successfully created user!'
          
          this.$router.push({ name: 'admin_users_edit', params: { id: response.id } });
        } else {
          response = await this.userStore.updateUser(this.$route.params.id, payload);
          this.successMessage = 'Successfully updated user!';  
        }
          this.isSaved = true;
          this.dirty = false;
          this.setSavableStateSuccess();
          this.parseUserResponse(response);
      } catch (error) {
        this.handleFormError(error);
      }
    },
    cancel() {
      this.$router.push({ name: 'admin_users' });
    },
    async testPhoneNumber() {
      this.setSavableStateProcessing();
      try {
        await this.userStore.testPhoneNumber(this.$route.params.id);
        this.successMessage = "You should receive a text within a few seconds";
        this.setSavableStateSuccess();
      } catch (error) {
        this.handleFormError(error);
      }
    }
  }
}
</script>
