<template>
  <div class="inline-row">
    <div class="action-row">
      <div class="alert message-component col-sm-12 mt-sm-1 mt-md-0"
        :class="{ 'alert-danger': !isEnabled, 'alert-success': isEnabled}"
      role="alert">
        {{ isEnabled ? "Robocall reduction is active" : "Robocall reduction is inactive"}}
      </div>
    </div>
    <div class="alert alert-thin alert-info col-sm-12 mt-sm-1 mt-md-0">
      <font-awesome-icon :icon="['fas', 'circle-info']" />
      {{`Last incoming email processed at ${last_email_processed_time} ET`}}
    </div>
    <ActionToolbar :secondary-enabled="false" :component-state="currentState" :error-message="formError"
      :primary-button-text="isEnabled ? 'Stop Robocall Reduction' : 'Start Robocall Reduction'"
      :primary-style="isEnabled ? 'danger' : 'success'"
      :primary-button-icon="isEnabled ? ['fa', 'ban'] : ['fa', 'check'] "
      @primary-click.prevent="enableOrDisableSettings()" />
    <p class="small">
      Status last changed by {{last_status_change}}
    </p>
  </div>
</template>
<script>
import { useRobocallReductionsStore } from '@/src/stores/robocall_reductions';
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
/**
 * The component handles both the new and edit pages for Application Settings */
 export default {
  components: {
    ActionToolbar,
  },
  props: {
    isEnabled: { required: true, type: Boolean },
    last_status_change: String,
    last_email_processed_time: String
  },
  mixins: [ savableStateMixin ],
  setup() {
    const robocallReductionsStore = useRobocallReductionsStore();
    return { robocallReductionsStore };
  },
  methods: {
    async enableOrDisableSettings() {
      try {
        this.setSavableStateProcessing();
        if(this.isEnabled) {
          this.$emit('reload', await this.robocallReductionsStore.disableRobocallReduction());
        }
        else {
          this.$emit('reload', await this.robocallReductionsStore.enableRobocallReduction())
        }
        this.setSavableStateSuccess();
      } catch(error) {
        this.handleFormError(error);
      }
    },
  }
}
</script>
