<template>
  <CardSection :error="majorError" :loading="isSavableStateLoading">
    <template #header>
      Manage Organs
    </template>
    <template #body>
      <PortalTable ref="organs_index" table-id="organs_index"
        :table-data="records"
        :total-records="totalRecords"
        :page="pageNumber"
        :page-count="pageCount"
        :loading="isSavableStateProcessing"
        :filter-values="filterValues"
        filter-display="row"
        edit-mode="row"
        empty="No organs found!"
        @on-sort="handleSortChange"
        @on-page="handlePageChange"
        @on-filter-change="onFilterChange"
        @on-row-edit-save="onRowEditSave"
        @on-row-edit-init="onRowEditInit">
        <template #columns>
          <Column field="name" 
            sort-field="name"
            header="Organ Name" 
            sortable
            filter-field="name"
            :show-filter-menu="false"
            :filter-menu-style="{ width: '15rem' }">
            <template #filter="{ filterModel = { value: null }, filterCallback }">
              <InputText v-model="filterModel.value"
                type="text"
                class="p-column-filter form-control"
                placeholder="Search by name"
                @input="filterCallback()" />
            </template>
          </Column>
          <Column field="organ_group.name" 
            header="Organ Group" 
            sort-field="organ_group"
            sortable
            filter-field="organ_group_id"
            :show-filter-menu="false"
            :filter-menu-style="{ width: '15rem' }">
            <template #filter>
              <MultiSelectFilter ref="organ_group_id" 
                cache-context-key="organ.organ_group_id"
                filter-field="organ_group_id"
                class="form-control form-control-select"
                option-label="name"
                :options="organGroupsStore.organGroups"
                @change="onChange" />
            </template>
            <template #editor="{ data }">
              <MultiSelectInput v-model="editRowData.groupId[data.id]"
                name="organ_group_id"
                label="Organ Groups"
                :options="organGroupsStore.organGroups"
                label-key="name"
                value-key="id"
                rules="required"
                placeholder="Select an Organ Group" />
          </template>
           <template #body="slotProps">
             {{ slotProps.data.organ_group.name }}
              <div
                class="col-sm-8 message-component" v-if="editRowData.message[slotProps.data.id]">
                <span :class="editRowData.currentState[slotProps.data.id] == SaveStates.Success ? 'alert alert-success' : 'alert-danger'">{{ editRowData.message[slotProps.data.id] }}
                  <button v-if="editRowData.currentState[slotProps.data.id]" type="button" class="close pl-2" data-dismiss="alert" @click="clearState" aria-label="Close">
                    <font-awesome-icon :icon="['fas', 'fa-times']" aria-hidden="true" />
                  </button>
                </span>
              </div>
            </template>
          </Column>
          <Column field="created_by.full_name" 
            header="Created By" />
          <Column field="updated_by.full_name" 
            header="Last Edited By" />
          <Column :row-editor="true" style="width: 10%; min-width: 8rem" body-style="text-align:center"/>          
        </template>
      </PortalTable>
    </template>
  </CardSection>
</template>

<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import { pageMixin } from "@/src/mixins/pageMixin";
import { FilterMatchMode } from 'primevue/api';
import MultiSelectFilter from "@/src/components/shared/FormComponents/MultiSelectFilter.vue";
import { useOrganGroupStore } from '@/src/stores/organ-groups';
import { useOrganStore } from '@/src/stores/organs';
import { paginationMixin } from "@/src/mixins/paginationMixin";
import InputText from 'primevue/inputtext';
import MultiSelectInput from "@/src/components/shared/FormComponents/MultiSelectInput.vue";
import Button from 'primevue/button';

export default {
   components: {
    CardSection,
    PortalTable,
    Column,
    MultiSelectFilter,
    InputText,
    MultiSelectInput,
    Button
  },
  mixins: [ savableStateMixin, pageMixin, paginationMixin ],
  setup() {
    const organGroupsStore = useOrganGroupStore();
    const organsStore = useOrganStore();
    return {
      organGroupsStore, organsStore
    }
  },
   data() {
     return {
      editRowData: {
        groupId: {},
        message: {},
        currentState: {}
       },
      filterValues: {
        "name" : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        "organ_group_id" : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      }
    }
  },
  async mounted() {
    try {
      this.setSavableStateLoading();
      await this.organGroupsStore.loadAllOrganGroups();
      // When user first comes to the page we will show unspecified organs
      const unspecified_group = this.organGroupsStore.organGroups.find((group) => {
        return group.name == "Unspecified"
      });
      if (unspecified_group) {
        window.localStorage.setItem('multiselectfilter.organ.organ_group_id', `${unspecified_group.id}`);
      }
      this.getRowData();
      this.setSavableStateIdle();
    } catch(error) {
      this.handleMajorError(error); 
    }
  
  },
  methods: {
    async getRowData(reset = false) {
      this.setSavableStateProcessing();
      if (reset) this.pageNumber = 1;

      let params = {
        page: this.pageNumber,
        sort_by: this.currentSortField,
        sort_type: this.currentSortType,
      };

      params = { ...this.search_params, ...params };

      try {
        const response = await this.organsStore.loadOrgans(params)
        this.processPagyResponse(response);
        this.setSavableStateIdle();
      } catch(error) {
      this.handleMajorError(error);  
      }
    },
    onChange(event) {
      this.$refs.organs_index.resetFilters(event);
    },
    onFilterChange(event) {
      if(event["organ_group_id"] === "") this.$refs.organ_group_id.clearFilters();

      this.search_params = event;
      this.getRowData(true);
    },
    async onRowEditSave(event) {
      try {
        this.editRowData.currentState[event.data.id] = this.SaveStates.Processing;

        const payload = this.editRowData.groupId[event.data.id] ? { organ_group_id: this.editRowData.groupId[event.data.id] } : {};
        await this.organsStore.updateOrganGroup(event.data.id, payload); 
        
        this.editRowData.groupId[event.data.id] = null;
        this.editRowData.currentState[event.data.id] = this.SaveStates.Success;
        this.editRowData.message[event.data.id] = 'Group updated successfully!';
        this.getRowData(true);
      } catch(error) {
        this.editRowData.currentState[event.data.id] = this.SaveStates.Errored;
        this.editRowData.message[event.data.id] =  "An error occurred!"
      };
    },
    onRowEditInit(event) {
      this.editRowData.currentState[event.data.id] = this.SaveStates.Blank;
    },
    clearState() {
      this.currentState = this.SaveStates.Blank;
      this.editRowData = {
        groupId: {},
        message: {},
        currentState: {}
       }
    }
  }
}
</script>