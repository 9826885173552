<template>
  <CardSection :error="majorError" :loading="isSavableStateLoading">
    <template #header>
      Manage Organ Groups
    </template>
    <template #body>
      <div class="form-group row">
        <div class="form-group mr-2 mb-0 col-12 d-flex">
          <router-link :to="{ name: 'admin_organ_groups_new' }" 
            class="btn btn-primary btn-sm ml-auto">
            Add New Organ Groups
          </router-link>
        </div>
      </div>

      <PortalTable ref="organ_groups_index" table-id="organ_groups_index"
        :table-data="records"
        :total-records="totalRecords"
        :page="pageNumber"
        :page-count="pageCount"
        :loading="isSavableStateProcessing"
        :filter-values="filterValues"
        filter-display="row"
        empty="No organ groups found!"
        @on-page="handlePageChange"
        @on-filter-change="onFilterChange">
        <template #columns>
          <Column field="name" 
            header="Organ Group Name"
            filter-field="name"
            :show-filter-menu="false">
            <template #filter="{ filterModel = { value: null }, filterCallback }">
              <InputText v-model="filterModel.value"
                type="text"
                class="p-column-filter form-control"
                placeholder="Search by name"
                @input="filterCallback()" />
            </template>
          </Column>
          <Column field="organ_code" 
            header="Organ Code"
            filter-field="organ_code"
            :show-filter-menu="false">
             <template #filter="{ filterModel = { value: null }, filterCallback }">
              <InputText v-model="filterModel.value"
                type="text"
                class="p-column-filter form-control"
                placeholder="Search by name"
                @input="filterCallback()" />
            </template>
          </Column>
          <Column field="created_by.full_name" 
            header="Created By" />
          <Column field="updated_by.full_name" 
            header="Last Edited By" />
          <Column header="Actions">
            <template #body="slotProps">
              <router-link :to="{ name: 'admin_organ_groups_edit', params: { id: slotProps.data.id } }"
                class="action btn btn-fill btn-outline-primary mx-2">
                Edit
              </router-link>
            </template>
          </Column>
        </template>
      </PortalTable>
    </template>
  </CardSection>
</template>

<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import { pageMixin } from "@/src/mixins/pageMixin";
import { FilterMatchMode } from 'primevue/api';
import MultiSelectFilter from "@/src/components/shared/FormComponents/MultiSelectFilter.vue";
import { useOrganGroupStore } from '@/src/stores/organ-groups';
import { paginationMixin } from "@/src/mixins/paginationMixin";
import InputText from 'primevue/inputtext';

export default {
   components: {
    CardSection,
    PortalTable,
    Column,
    MultiSelectFilter,
    InputText
  },
  mixins: [ savableStateMixin, pageMixin, paginationMixin ],
  setup() {
    const organGroupsStore = useOrganGroupStore();
    return {
      organGroupsStore
    }
  },
   data() {
    return {
     filterValues: {
        "name" : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        "organ_code" : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      }
    }
  },
  async mounted() {
    this.setSavableStateLoading();
    this.getRowData();
  },
  methods: {
    async getRowData(reset = false) {
      this.setSavableStateProcessing();
      if (reset) this.pageNumber = 1;

      let params = {
        page: this.pageNumber,
        sort_by: this.currentSortField,
        sort_type: this.currentSortType,
      };

      params = { ...this.search_params, ...params };

      try {
        const response = await this.organGroupsStore.loadOrganGroups(params)
        this.processPagyResponse(response);
        this.setSavableStateIdle();
      } catch(error) {
      this.handleMajorError(error);  
      }
    },
    onChange(event) {
      this.$refs.organ_groups_index.resetFilters(event);
    },
    onFilterChange(event) {
      this.search_params = event;
      this.getRowData(true);
    }
  }
}
</script>