import { defineStore } from 'pinia'
import beApiClient from "@/src/be-api-client";
import {APIRoute, EP } from "@/src/endpoints";
import { handleErrors, makeRequest } from "@/src/utils";

export const useOrganStore = defineStore('organ', {
  state: ()=> {
    return {
      // lookup data
    }
  },
  getters: {
   
  },
  actions: {
    // Generic load data method
    loadEntity(url, key, params = {}) {
      return new Promise((resolve, reject) => {
        beApiClient.get(url, params).then(response => {
          this[key] = response.data;
          resolve();
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        });
      });
    },

    // Load organs
    loadOrgans(params) {
      return makeRequest(beApiClient.get, APIRoute(EP.admin.organs.index), { params: params });
    },
    // Update Organ Group
    updateOrganGroup(id, payload) {
      return makeRequest(beApiClient.put, APIRoute(EP.admin.organs.update, { id: id}), { organ: payload });
    },
  }
});