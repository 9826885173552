<template>
  <CardSection :loading="isSavableStateLoading"
    :error="majorError"
    header-class="title-bar">
    <template #header>
      {{ createNew ? 'New' : 'Edit' }}
      Organ Group
    </template>

    <template #body>
      <SubSection>
        <template #body>
          <VeeForm v-if="selectedOrganGroup" @submit="onSubmit">           
            <div class="row">
               <TextInput v-model="selectedOrganGroup.name"
                name="name"
                label="Group Name"
                rules="required"
                col-style="form-group-standard-column" />

               <TextInput v-model="selectedOrganGroup.organ_code"
                name="code"
                label="Organ Code"
                rules="required"
                v-mask="'AAAAA'"
                col-style="form-group-standard-column" />
            </div>

            <!-- save controls -->
             <ActionToolbar :component-state="currentState" 
              :error-message="formError"  
              primary-button-text="Save"
              :secondary-button-text="createNew ? 'Cancel' : 'Close'"
              @secondary-click="cancel" />
          </VeeForm>
        </template>
      </SubSection>
    </template>
  </CardSection>
</template>

<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import SubSection from "@/src/components/shared/SubSection.vue";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import { Form as VeeForm } from 'vee-validate';
import { useOrganGroupStore } from '@/src/stores/organ-groups';
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import TextInput from "@/src/components/shared/FormComponents/TextInput.vue";

export default {
  components: {
    CardSection,
    SubSection,
    ActionToolbar,
    VeeForm,
    TextInput
  },
  mixins: [ savableStateMixin ],
  setup() {
    const organGroupsStore = useOrganGroupStore();
    return {
      organGroupsStore
    }
  },
  data() {
    return {
      selectedOrganGroup: {},
      successMessage: null,
    }
  },
  computed: {
    createNew() {
      return this.$route.params.id ? false : true;
    }
  },
 async mounted() {
    this.setSavableStateLoading();
    this.selectedOrganGroup = {};

    try {
      if(!this.createNew) {
        // on edit
        const response = await this.organGroupsStore.loadOrganGroup(this.$route.params.id)
        this.selectedOrganGroup = {
          id: response.id,
          name: response.name,
          organ_code: response.organ_code
        }
      } 
      this.setSavableStateIdle();
    } catch(error) {
      this.handleMajorError(error);
    }
  },
  methods: {
    onSubmit() {
      if (this.createNew) {
        this.create(this.selectedOrganGroup);
      } else {
        this.update(this.selectedOrganGroup);
      }
    },
    async create(payload) {
      try {
        this.setSavableStateProcessing();

        const response = await this.organGroupsStore.createOrganGroup(payload);
        this.selectedOrganGroup = {
          id: response.id,
          name: response.name,
          organ_code: response.organ_code
        }
        this.$router.push({ name: 'admin_organ_groups_edit', params: { id: response.id }});
        this.setSavableStateSuccess();
      } catch(error) {
        this.handleFormError(error);
      }
    },
    async update(payload) {
      this.setSavableStateProcessing();
      try {
        await this.organGroupsStore.updateOrganGroup(payload)
        this.setSavableStateSuccess();
      } catch (error) {
        this.handleFormError(error);
      }
    },
    cancel() {
      this.$router.push({ name: 'admin_organ_groups' });
    },
  }
}
</script>