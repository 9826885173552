import { defineStore } from 'pinia'
import beApiClient from "@/src/be-api-client";
import {APIRoute, EP } from "@/src/endpoints";
import { handleErrors, makeRequest } from "@/src/utils";

export const useOrganGroupStore = defineStore('organ-groups', {
  state: ()=> {
    return {
      // lookup data
      organGroups: [],
    }
  },
  getters: {
   
  },
  actions: {
    // Generic load data method
    loadPaginatedEntity(url, key, params = {}) {
      return new Promise((resolve, reject) => {
        beApiClient.get(url, {
          headers: {'Accept': 'application/json'},
          params: params })
          .then(response => {
            this[key] = response.data;
            resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        });
      });
    },
    // load organ groups
    loadAllOrganGroups() {
      return this.loadPaginatedEntity(APIRoute(EP.admin.organ_groups.index), 'organGroups',{ all: true })
    },
    // Load organ groups
    loadOrganGroups(params) {
      return makeRequest(beApiClient.get, APIRoute(EP.admin.organ_groups.index), { params: params });
    },
    createOrganGroup(payload) {
      return makeRequest(beApiClient.post, APIRoute(EP.admin.organ_groups.create), { organ_group: payload });
    },
    updateOrganGroup(payload) {
      return makeRequest(beApiClient.patch, APIRoute(EP.admin.organ_groups.update, { id: payload.id }), { organ_group: payload });
    },
    loadOrganGroup(id) {
      return makeRequest(beApiClient.get, APIRoute(EP.admin.organ_groups.show, { id: id }));
    },
  }
});